console.log('components');


const items = document.querySelectorAll(".accordion button");

function toggleAccordion() {
  const itemToggle = this.getAttribute('aria-expanded');

  for (let i = 0; i < items.length; i++) {  // Объявление переменной i
    items[i].setAttribute('aria-expanded', 'false');
  }

  if (itemToggle === 'false') {  // Использование строгого равенства
    this.setAttribute('aria-expanded', 'true');
  }
}

items.forEach(item => item.addEventListener('click', toggleAccordion));
